import React, { Component , Fragment } from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';




class CounterOne extends Component{
    state = {
        didViewCountUp: false
    };
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({didViewCountUp: true});
        }
    }
    render(){
        let Data = [
            {
                countNum : 24,
                countTitle: 'Zakonska obveza svakog gospodarskog subjekta je imati ovlaštenu i licenciranu tvrtku za obavljanje poslova zaštite imovine, tehničke zaštite i zaštite od požara. Naše usluge zaštite nudimo od 0-24 sata 365 dana u godini. Prilagođavamo se vašim specifičnim zahtjevima te možemo ponuditi dodatna rješenja za vaše posebne potrebe iz područija zaštite.                ',
            },
            {
                countNum : 150,
                countTitle: 'Za sigurnost vas i vaše tvrtke osigurati ćemo pouzdane zaštitare, čuvare, vartogasce, tjelohranitelje. Vrijeme je novac, mi smo tu da sačuvamo vašu imovinu i vaš novac a vi svoje vrijeme i pažnju možete mirno posvetiti svojoj osnovnoj djelatnosti.                ',
            },
            {
                countNum : 1997,
                countTitle: 'Naš ugled govori za sebe te smo uz vas od 1997 godine: Iskustvo nas je naučilo da je zadovoljstvo korisnika na temelju kvalitetno odrađena posla naš prvi i najvažniji zadatak. Na raspolaganju smo Vam uvijek kada zatrebate našu pomoć.                ',
                dot: "." 
            },
        ];

        return(
            <Fragment>
                <div className="row">
                    {Data.map( (value, index) => (
                        <div className={`counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12 ${value.dot && "dot-active"}`} key={index}>
                            <h5 className="counter">
                                <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                                    <CountUp end={this.state.didViewCountUp ? value.countNum : 0} />
                                </VisibilitySensor>
                            </h5>
                            <p className="description">{value.countTitle}</p>
                        </div>
                    ))}
                </div>
            </Fragment>
        )
    }
}
export default CounterOne;