import React, { Component, Fragment } from "react";

import Scrollspy from "react-scrollspy";
import ScrollToTop from "react-scroll-up";
import Particles from "react-particles-js";
import { FiChevronUp, FiX, FiMenu } from "react-icons/fi";
import ServiceList from "../elements/service/ServiceList";
import CounterOne from "../elements/counters/CounterOne";
import BlogContent from "../elements/blog/BlogContent";
import FooterTwo from "../component/footer/FooterTwo";
import Contact from "../elements/contact/ContactTwo";
import ContactFour from "../elements/contact/ContactFour";
import ContactFive from "../elements/contact/ContactFive";
import Helmet from "../component/common/Helmet";

function scrollToTestDiv(){
  const divElement = document.getElementById('service');
  divElement.scrollIntoView({behavior:"smooth"});
}

function scrollToTestDiv2(){
  const divElement = document.getElementById('home');
  divElement.scrollIntoView({behavior:"smooth"});
}

function scrollToTestDiv3(){
  const divElement = document.getElementById('about');
  divElement.scrollIntoView({behavior:"smooth"});
}

function scrollToTestDiv4(){
  const divElement = document.getElementById('contact');
  divElement.scrollIntoView({behavior:"smooth"});
}
const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    title: "P&F zaštita",
    description: (
      <div class="hero-icons">
        <div class="icon-wrapper">
          <a onClick={scrollToTestDiv}>
            <img src="/assets/images/homepage-icons/tjelesnazaštitahomepage.svg" />
            <p>TJELESNA ZAŠTITA</p>
          </a>
        </div>
        <div class="icon-wrapper">
        <a onClick={scrollToTestDiv}>
            <img src="/assets/images/homepage-icons/tehničkazaštitahomepage.svg" />
            <p>TEHNIČKA ZAŠTITA</p>
          </a>
        </div>
        <div class="icon-wrapper">
        <a onClick={scrollToTestDiv}>
            <img src="/assets/images/homepage-icons/recepcijskeusluge.svg" />
            <p>RECEPCIJSKE USLUGE</p>
          </a>
        </div>
        <div class="icon-wrapper">
        <a onClick={scrollToTestDiv}>
            <img src="/assets/images/homepage-icons/vatrogasci.svg" />
            <p>VATROGASCI</p>
          </a>
        </div>

      </div>
    ),
  },
];

const list = [
  {
    image: "image-1",
    category: "Banka",
    title: "Ljubljanska banka",
  },
  {
    image: "image-2",
    category: "Farmacija",
    title: "Krka-Farma d.o.o",
  },
  {
    image: "image-3",
    category: "Svijet pića",
    title: "Roto-promet",
  },
  {
    image: "image-4",
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: "image-3",
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: "image-4",
    category: "Development",
    title: "Getting tickets to the big show",
  },
];

class HomeParticles extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  stickyHeader() {}
  render() {
    const PostList = BlogContent.slice(0, 5);

    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    return (
      <Fragment>
        <Helmet pageTitle="P&F zaštita" />

        {/* Start Header Area  */}
        <header className="header-area formobile-menu header--fixed default-color">
          <div className="header-wrapper" id="header-wrapper">
            <div className="header-left">
              <div className="logo">
                <a href="/">
                  <img
                    className="logo-1"
                    src="/assets/images/logo/logo-light.png"
                    alt="Logo Images"
                  />
                  <img
                    className="logo-2"
                    src="/assets/images/logo/logo-all-dark.png"
                    alt="Logo Images"
                  />
                </a>
              </div>
            </div>
            <div className="header-right">
              <nav className="mainmenunav d-lg-block">
                <Scrollspy
                  className="mainmenu"
                  items={[
                    "home",
                    "service",
                    "about",
                    "contact",
                  ]}
                  currentClassName="is-current"
                  offset={-200}
                >
                  <li>
                    <a onClick={scrollToTestDiv2}>Početna</a>
                  </li>
                  <li>
                    <a onClick={scrollToTestDiv}>Usluge</a>
                  </li>
                  <li>
                    <a onClick={scrollToTestDiv3}>O nama</a>
                  </li>
                  <li>
                    <a onClick={scrollToTestDiv4}>Kontakt</a>
                  </li>
                </Scrollspy>
              </nav>
              {/* Start Humberger Menu  */}
              <div className="humberger-menu d-block d-lg-none pl--20">
                <span
                  onClick={this.menuTrigger}
                  className="menutrigger text-white"
                >
                  <FiMenu />
                </span>
              </div>
              {/* End Humberger Menu  */}
              <div className="close-menu d-block d-lg-none">
                <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                  <FiX />
                </span>
              </div>
            </div>
          </div>
        </header>
        {/* End Header Area  */}

        {/* Start Slider Area   */}

        <div
          className="slider-activation slider-creative-agency with-particles"
          id="home"
        >
          <div className="frame-layout__particles">
            <Particles
              params={{
                particles: {
                  number: {
                    value: 50,
                  },
                  size: {
                    value: 4,
                  },
                },
                interactivity: {
                  events: {
                    onhover: {
                      enable: true,
                      mode: "repulse",
                    },
                  },
                },
              }}
            />
          </div>
          <div className="bg_image bg_image--27">
            {SlideList.map((value, index) => (
              <div
                className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center"
                key={index}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={`inner ${value.textPosition}`}>
                        {value.category ? <span>{value.category}</span> : ""}
                        {value.title ? (
                          <h1 className="title">{value.title}</h1>
                        ) : (
                          ""
                        )}
                        {value.description ? (
                          <p className="description">{value.description}</p>
                        ) : (
                          ""
                        )}
                        {value.buttonText ? (
                          <div className="slide-btn">
                            <a
                              className="rn-button-style--2 btn-primary-color"
                              href={`${value.buttonLink}`}
                            >
                              {value.buttonText}
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start Service Area  */}
        <div className="service-area creative-service-wrapper ptb--120 active-dark bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-12">
                <div className="section-title text-left mb--30">
                  <h2>Naše Usluge</h2>
                  <p>
                    Zakonska obveza svakog gospodarskog subjekta je imati
                    ovlaštenu i licenciranu tvrtku za obavljanje poslova zaštite
                    imovine, tehničke zaštite i zaštite od požara. Naše usluge
                    zaštite nudimo od 0-24 sata 365 dana u godini. Prilagođavamo
                    se vašim specifičnim zahtjevima te možemo ponuditi dodatna
                    rješenja za vaše posebne potrebe iz područija zaštite. Za
                    sigurnost vas i vaše tvrtke osigurati ćemo pouzdane
                    zaštitare, čuvare, vartogasce, tjelohranitelje. Vrijeme je
                    novac, mi smo tu da sačuvamo vašu imovinu i vaš novac a vi
                    svoje vrijeme i pažnju možete mirno posvetiti svojoj
                    osnovnoj djelatnosti. Naš ugled govori za sebe: Iskustvo nas
                    je naučilo da je zadovoljstvo korisnika na temelju
                    kvalitetno odrađena posla naš prvi i najvažniji zadatak. Na
                    raspolaganju smo Vam uvijek kada zatrebate našu pomoć.
                  </p>
                </div>
              </div>
            </div>
            <div className="row creative-service">
              <div id="service" className="col-lg-12">
                <ServiceList
                  item="6"
                  column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start About Area */}
        <div className="about-area ptb--120 active-dark bg_color--5" id="about">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/about/about-3.jpg"
                      alt="About Images"
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">O nama</h2>
                      <p className="description">
                        U startu smo orijentirani prvenstveno na zaštitarske
                        usluge. Preko 30 godina iskustva u poslovima tjelesne i
                        tehničke zaštite te zaštite od požara. Tvrtka je
                        stvorena vlastitim kapitalom i idejom o stvaranju
                        prepoznatljivog brenda na tržištu; sa iskusnim
                        djelatnicima, nakon osnivanja tvrtke prisupilo se
                        ulaganju u zaposlenike kroz dodatna osposobljavanja i
                        licence za obavljanje poslova.
                      </p>
                    </div>
                    <div className="row mt--30">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Misija</h3>
                          <p>
                            Naša misija je pružiti vrhunsku uslugu, biti vaš
                            pouzdan i povjerljiv partner. Naše poslovanje
                            spremni smo prilagoditi vašim specifičnim potrebama
                            i zahtjevima vaše djelatnosti. U mogućnosti smo
                            sačuvati vašu imovinu,privatnosti te poslovne
                            podatke kao i omogućiti fizičke i tehničke zaštite
                            te zaštite od požara.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Naši Djelatnici</h3>
                          <p>
                            Naši djelatnici su visoko obučeni i obrazovani
                            djelatnici s odobrenjem ministarstva, čuvari,
                            zaštitari, zaštitari-tehničari, vatrogasci. Svi naši
                            djelatnici posjeduju licencu, dopuštenje za
                            obavljanje ovih poslova u skladu sa zakonskim
                            osnovama.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area */}

        {/* Start CounterUp Area */}
        <div className="rn-counterup-area pt--140 p pb--110 active-dark bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h3 className="fontWeight500">Zanimljivosti</h3>
                </div>
              </div>
            </div>
            <CounterOne />
          </div>
        </div>
        {/* End CounterUp Area */}

        {/* Start Contact Us */}
        <div className="contact-area tabs ptb--120 active-dark bg_color--5" id="contact">
          <Tabs>
            <Tab class="button-size" label="Upitnik za kandidate">
              <div
                className="rn-contact-us ptb--120 active-dark bg_color--5"
                id="contact"
              >
                <Contact />
              </div>
            </Tab>
            <Tab class="button-size" label="Zatraži ponudu">
              <div
                className="rn-contact-us ptb--120 active-dark bg_color--5"
                id="contact"
              >
                <ContactFour />
              </div>
            </Tab>
            <Tab class="button-size" label="Osnovni podaci">
              <div
                className="rn-contact-us ptb--120 active-dark bg_color--5"
                id="contact"
              >
                <ContactFive />
              </div>
            </Tab>
          </Tabs>
        </div>
        {/* End Contact Us */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default HomeParticles;

class Tabs extends React.Component {
  state = {
    activeTab: this.props.children[0].props.label,
  };
  changeTab = (tab) => {
    this.setState({ activeTab: tab });
  };
  render() {
    let content;
    let buttons = [];
    return (
      <div>
        {React.Children.map(this.props.children, (child) => {
          buttons.push(child.props.label);
          if (child.props.label === this.state.activeTab)
            content = child.props.children;
        })}

        <TabButtons
          activeTab={this.state.activeTab}
          buttons={buttons}
          changeTab={this.changeTab}
        />
        <div className="tab-content">{content}</div>
      </div>
    );
  }
}

const TabButtons = ({ buttons, changeTab, activeTab }) => {
  return (
    <div className="tab-buttons button-size">
      {buttons.map((button) => {
        return (
          <button
            className={
              button === activeTab
                ? "active rn-button-style--2 btn-solid"
                : "rn-button-style--2 btn-solid"
            }
            onClick={() => changeTab(button)}
          >
            {button}
          </button>
        );
      })}
    </div>
  );
};

const Tab = (props) => {
  return <React.Fragment>{props.children}</React.Fragment>;
};
