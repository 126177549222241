import React, { Component } from "react";
import ContactForm from "./ContactForm";

class ContactTwo extends Component {
  render() {
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title"> P&F ZAŠTITA d.o.o</h2>
                <p className="description">
                  Savska cesta 41, 10000 Zagreb<br/>
                  Matični broj: 1277464<br/>
                  OIB:95517402410<br/> 
                  Broj računa : 2340009-1110055937 <br/>
                  tel: +385 16112176 <br/>
                  faks: +385 1 6112176 <br/>
                  e-mail: info@pf-zastita.hr<br/>
                </p>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail mb_md--30 mb_sm--30">
                <img src="/assets/images/about/about-8.jpg" alt="trydo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactTwo;
