import React, { Component, Fragment } from "react";

function CookieBot() {
  return (
    <div>
      {" "}
      <script
        id="CookieDeclaration"
        src="https://consent.cookiebot.com/009e9d8b-4246-4803-8415-af0f7856203d/cd.js"
        type="text/javascript"
        async
      ></script>
    </div>
  );
}

export default CookieBot;
